import React, { Component } from "react";
import Routes from "./components/Routes/Routes";
import { Link, withRouter } from 'react-router-dom';
import { Auth, API } from "aws-amplify";
import {
  Button,
  Col,
  Grid,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
  MenuItem,
  Modal,
  Row
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      bsClass: '',
      modalTitle: '',
      modalMessage: '',
      isLoading: true,
      isAuthenticated: false,
      isAdmin: false,
      purseLoading: true,
      purseObj: {},
      profile: {},
      profilePic: ''
    };
    this.recalculatePurse = this.recalculatePurse.bind(this);
    this.reloadProfile = this.reloadProfile.bind(this);
  }

  recalculatePurse = async () => {
    await this.loadMyPurse();
    await this.loadMyProfile();
  };
  reloadProfile = async () => await this.loadMyProfile();
  async componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps).length !== 0) {
      try {
        if (Object.keys(this.state.profile).length === 0) {
          await this.loadMyProfile();
          await this.loadMyPurse();
        }
      } catch (e) {
        this.setState({
          bsClass: 'modal-header modal-header-danger',
          modalTitle: 'Error',
          modalMessage: 'There was a problem loading the app.',
          showModal: true
        });
      } finally {
        this.setState({isLoading: false});
      }
    }
  }

  async componentDidMount() {
    this.loadFacebookSDK();
    try {
      const user = await Auth.currentAuthenticatedUser();
      // the user is a POOL user
      if (user.username) {
        this.userHasAuthenticated(true);
        if (user.signInUserSession.idToken.payload['cognito:groups'] && user.signInUserSession.idToken.payload['cognito:groups'].includes("Admins")) {
          this.userIsAdmin(true);
        }
      }
      // the user is a FEDERATED user
      if (user.id) {
        this.userHasAuthenticated(true);
      }
      if (user) {
        if (Object.keys(this.state.profile).length === 0) {
          await this.loadMyProfile();
          await this.loadMyPurse();
        }
      }
    } catch(e) {
      if (e !== 'not authenticated') {
        this.setState({
          bsClass: 'modal-header modal-header-danger',
          modalTitle: 'Error',
          modalMessage: 'There was a problem with your account. Please try logging in again.',
          showModal: true
        });
      }
      await Auth.signOut();
      this.userHasAuthenticated(false);
    } finally {
      this.setState({isLoading: false});
      // see if the cookie consent is set.
      // If so, then add the cookieConsentTrue class so we can adjust the padding of the application container depending on the viewport width by using media queries
      if (document.cookie.includes("CookieConsent=true")){
        document.getElementById('root').classList.add('cookieConsentTrue');
      }
    }
  }


  async loadMyProfile()  {
    if (this.state.isAuthenticated) {
      try {
        const profile = await API.get("api", "/profile/me", {headers: {},});
        this.userProfileObj(profile);
        const profilePicList = await Storage.list('', {
          level: 'protected',
          identityId: profile.cognitoIdentityId
        });
        profilePicList.map(async (pic) => {
          if (pic.key === 'profile.jpg') {
            const profilePic = await Storage.get('profile.jpg', {
              level: 'protected',
              identityId: profile.cognitoIdentityId
            });
            this.setState({profilePic});
          }
        });
      } catch (e) {
        this.setState({profilePic: ''});
      }
    }
  }

  async loadMyPurse() {
    if (this.state.isAuthenticated) {
      this.setState({purseLoading: true});
      /* Load Token Balance */
      try {
        const purse = await API.get("api", "/tokens/purse", {headers: {},});
        this.userPurseObj(purse);
      } catch (e) {
        this.setState({
          bsClass: 'modal-header modal-header-danger',
          modalTitle: 'Error',
          modalMessage: 'There was a problem loading your token balance.',
          showModal: true
        });
      } finally {
        this.setState({purseLoading: false});
      }
    } else {
      this.setState({purseLoading: false});
    }
  }

  userHasAuthenticated = authenticated => {this.setState({ isAuthenticated: authenticated });};
  userIsAdmin = admin => {this.setState({ isAdmin: admin });};
  userPurseObj = obj => {this.setState({ purseObj: obj });};
  userProfileObj = obj => this.setState({profile: obj});
  loadFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : process.env.REACT_APP_FB_LOGIN_APP_ID,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.1'
      });
      // Update dispatch event to add a new event that indicates that the FB has been initialized
      // then addEventListener in componentDidMount per example on homepage at line 45
      document.dispatchEvent(new Event('fb_init'));
    };
    (function(d, s, id){
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }
  handleLogout = async event => {
    await Auth.signOut();
    this.setState({profilePic: ''});
    this.userHasAuthenticated(false);
    this.userIsAdmin(false);
    this.userPurseObj({});
    this.props.history.push("/login");
  };

  modalClose = event => {this.setState({ showModal: false });};

  navbarCreditLink() {
    if (this.state.isAuthenticated) {
      if (!this.state.purseLoading) {
        if (Number(this.state.purseObj.purseQty) >= 1000000) {
          return (this.state.purseObj.purseQty/1000000)+'M+';
        }
        if (Number(this.state.purseObj.purseQty) > 0) {
          return this.state.purseObj.purseQty.toLocaleString();
        }return 'Buy';
      }return <span className="LoaderButton"><i className="fa fa-refresh fa-fw spinning" style={{marginRight:-2}} aria-hidden="true"/></span>
    }return 'Buy';
  }

  socialMediaLinks() {
    return (
      <div className="pull-right">
        <a
          style={{paddingLeft:5}}
          href="https://facebook.com/JasperThayer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button bsStyle="default" bsSize="xsmall">
            <i className="fa fa-facebook fa-fw"/>
          </Button>
        </a>

        <a
          style={{paddingLeft:5}}
          href="https://instagram.com/JasperThayer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button bsStyle="default" bsSize="xsmall">
            <i className="fa fa-instagram fa-fw"/>
          </Button>
        </a>

        <a
          style={{paddingLeft:5}}
          href="https://twitter.com/JasperThayer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button bsStyle="default" bsSize="xsmall">
            <i className="fa fa-twitter fa-fw"/>
          </Button>
        </a>

        <a
          style={{paddingLeft:5}}
          href="https://github.com/JasperThayer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button bsStyle="default" bsSize="xsmall">
            <i className="fa fa-github fa-fw"/>
          </Button>
        </a>

        <a
          style={{paddingLeft:5}}
          href="https://linkedin.com/in/JasperThayer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button bsStyle="default" bsSize="xsmall">
            <i className="fa fa-linkedin fa-fw"/>
          </Button>
        </a>
      </div>
    );
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      isAdmin: this.state.isAdmin,
      userIsAdmin: this.userIsAdmin,
      purseObj: this.state.purseObj,
      recalculatePurse: this.recalculatePurse
    };

    return (
      <div>
        <Navbar fixedTop collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to='/'><i className="fa fa-home"/></Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>

              <LinkContainer to="/blog">
                <NavItem eventKey={2}>
                  <i className="fa fa-rss"/> Blog
                </NavItem>
              </LinkContainer>

              {/*<LinkContainer to="/pics">
                <NavItem eventKey={3}>
                  <i className="fa fa-camera"/> Pics
                </NavItem>
              </LinkContainer>*/}

              <LinkContainer to="/podcast">
                <NavItem eventKey={3}>
                  <i className="fa fa-podcast"/> Podcast
                </NavItem>
              </LinkContainer>

              <LinkContainer to="/music">
                <NavItem eventKey={4}>
                  <i className="fa fa-headphones"/> Music
                </NavItem>
              </LinkContainer>

              <LinkContainer to="/contact">
                <NavItem eventKey={5}>
                  <i className="fa fa-envelope"/> Contact
                </NavItem>
              </LinkContainer>


              {/*<LinkContainer to="/live">
                <NavItem eventKey={6}>
                  <i className="BlinkingIcon fa fa-video-camera"/> LIVE
                </NavItem>
              </LinkContainer>*/}

            </Nav>

            <Nav pullRight>

              {/*<LinkContainer to="/checkout">
                <NavItem eventKey={8}>
                  <i className="fa fa-money"/> {this.navbarCreditLink()} Credits
                </NavItem>
              </LinkContainer>*/}

              {!this.state.isAuthenticated && !this.state.isLoading && <LinkContainer to="/login">
                  <NavItem eventKey={8}>
                    <i className="fa fa-sign-in"/> Login
                  </NavItem>
                </LinkContainer>}

              {!this.state.isAuthenticated && this.state.isLoading &&
                <NavItem eventKey={10}>
                  <span className="LoaderButton"><i className="fa fa-refresh fa-fw spinning" aria-hidden="true"/></span>
                </NavItem>}

              {this.state.isAuthenticated && <NavDropdown eventKey={10} title={<i className="fa fa-fw fa-gear"/>} id="nav-dropdown">
                {this.state.isAdmin && <MenuItem header>Admin</MenuItem>}
                {this.state.isAdmin && <LinkContainer to="/admin/blog">
                  <MenuItem eventKey={11.1}><i className="fa fa-fw fa-rss"/> Blog Admin</MenuItem>
                </LinkContainer>}
                {this.state.isAdmin && <LinkContainer to="/admin/pics">
                  <MenuItem eventKey={11.1}><i className="fa fa-fw fa-camera"/> Pics Admin</MenuItem>
                </LinkContainer>}
                {this.state.isAdmin && <LinkContainer to="/admin/podcast">
                  <MenuItem eventKey={11.3}><i className="fa fa-fw fa-podcast"/> Podcast Admin</MenuItem>
                </LinkContainer>}
                {this.state.isAdmin && <LinkContainer to="/admin/music">
                  <MenuItem eventKey={11.4}><i className="fa fa-fw fa-headphones"/> Music Admin</MenuItem>
                </LinkContainer>}
                {this.state.isAdmin && <LinkContainer to="/admin/user">
                  <MenuItem eventKey={11.2}><i className="fa fa-fw fa-users"/> User Admin</MenuItem>
                </LinkContainer>}
                {this.state.isAdmin && <MenuItem divider />}
                <MenuItem header>Profile</MenuItem>
                <LinkContainer to="/profile">
                  <MenuItem eventKey={11.5}>
                    {this.state.profilePic && <img style={{width:'1.28571429em', border:'solid 1px #000000'}} alt={`${process.env.REACT_APP_DOMAIN_NAME} Profile Pic`} src={this.state.profilePic}/>}
                    {!this.state.profilePic && <i className="fa fa-fw fa-user"/>} My Profile
                  </MenuItem>
                </LinkContainer>
                <MenuItem eventKey={11.6} onClick={this.handleLogout}>
                  <i className="fa fa-fw fa-sign-out"/> Log Out
                </MenuItem>
              </NavDropdown>}





            </Nav>















          </Navbar.Collapse>
        </Navbar>

        <Routes childProps={childProps} />

        <Grid className="footer">
          <Row className="show-grid">
            <Col xsHidden sm={12}>
              <div className="pull-left">
                &copy; {new Date().getFullYear()} <Link to="/">{process.env.REACT_APP_DOMAIN_NAME}</Link>
                {' | '}<Link style={{fontSize:11}} to="/about">About</Link>
                {' | '}<Link style={{fontSize:11}} to="/privacy">Privacy Policy</Link>
                {' | '}<Link style={{fontSize:11}} to="/terms">Terms of Use</Link>
              </div>
              {this.socialMediaLinks()}
            </Col>

            <Col smHidden mdHidden lgHidden xs={12}>
              <div className="pull-left">
                <Link style={{fontSize:11}} to="/about">About</Link>
                {' | '}<Link style={{fontSize:11}} to="/privacy">Privacy</Link>
                {' | '}<Link style={{fontSize:11}} to="/terms">Terms</Link>
              </div>
              {this.socialMediaLinks()}
            </Col>
          </Row>
        </Grid>

        <Modal show={this.state.showModal} onHide={this.modalClose}>
          <Modal.Header bsClass={this.state.bsClass} closeButton>
            <i className="fa fa-warning fa-fw" aria-hidden="true"/> {this.state.modalTitle}
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.modalMessage}</p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(App);