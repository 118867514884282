import Amplify from "aws-amplify";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/index.css';
import 'font-awesome/css/font-awesome.min.css';
import registerServiceWorker from './registerServiceWorker';
import App from "./App";
Amplify.configure({
  Analytics: {
    AWSPinpoint: {
      appId: process.env.REACT_APP_PINPOINT_ID,
      region: process.env.REACT_APP_REGION
    }
  },
  Auth: {
    mandatorySignIn: false,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_REGION+process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_REGION+process.env.REACT_APP_COGNITO_FEDERATED_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
  },
  Storage: {
    track: true,
    region: process.env.REACT_APP_REGION,
    bucket: process.env.REACT_APP_S3_BUCKET,
    identityPoolId: process.env.REACT_APP_REGION+process.env.REACT_APP_COGNITO_FEDERATED_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: "https://"+process.env.REACT_APP_API_GATEWAY_STAGE+process.env.REACT_APP_DOMAIN_NAME
      }
    ]
  }
});

ReactDOM.render(
  <Router>
    <App/>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();