import React from "react";
import { Switch } from "react-router-dom";
import asyncComponent from "./AsyncComponent";
import AppliedRoute from "./AppliedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";

const AsyncHomepage = asyncComponent(() => import("../../pages/Homepage"));
const AsyncSignup = asyncComponent(() => import("../Authentication/Signup"));
const AsyncLogin = asyncComponent(() => import("../Authentication/Login"));
const AsyncResetPassword = asyncComponent(() => import("../Authentication/ResetPassword"));
const AsyncCheckout = asyncComponent(() => import("../../pages/Checkout"));
const AsyncAbout = asyncComponent(() => import("../../pages/About"));
const AsyncPrivacy = asyncComponent(() => import("../../pages/Privacy"));
const AsyncTerms = asyncComponent(() => import("../../pages/Terms"));
const AsyncContact = asyncComponent(() => import("../../pages/Contact"));
const AsyncPageNotFound = asyncComponent(() => import("../../pages/PageNotFound"));

const AsyncBlogAdmin = asyncComponent(() => import("../../pages/Admin/BlogAdmin"));
const AsyncPodcastAdmin = asyncComponent(() => import("../../pages/Admin/PodcastAdmin"));
const AsyncUserAdmin = asyncComponent(() => import("../../pages/Admin/ProfileAdmin"));

const AsyncBlog = asyncComponent(() => import("../../pages/Blog/Blogs"));
const AsyncBlogPost = asyncComponent(() => import("../../pages/Blog/BlogPost"));
const AsyncBlogCategory = asyncComponent(() => import("../../pages/Blog/BlogCategory"));
const AsyncBlogTag = asyncComponent(() => import("../../pages/Blog/BlogTag"));

const AsyncPodcast = asyncComponent(() => import("../../pages/Podcast/Podcasts"));

const AsyncMusic = asyncComponent(() => import("../../pages/Music"));
const AsyncLive = asyncComponent(() => import("../../pages/Live"));

const AsyncProfileView = asyncComponent(() => import("../../pages/Profile/ProfileView"));
const AsyncProfileMe = asyncComponent(() => import("../../pages/Profile/ProfileMe"));

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute exact path="/" component={AsyncHomepage} props={childProps} />
    <UnauthenticatedRoute exact path="/signup" component={AsyncSignup} props={childProps} />
    <UnauthenticatedRoute path="/login" component={AsyncLogin} props={childProps} />
    <UnauthenticatedRoute path="/reset-password" component={AsyncResetPassword} props={childProps} />

    <AuthenticatedRoute path="/checkout" component={AsyncCheckout} props={childProps} />
    <AppliedRoute path="/about" component={AsyncAbout} props={childProps} />
    <AppliedRoute path="/privacy" component={AsyncPrivacy} props={childProps} />
    <AppliedRoute path="/terms" component={AsyncTerms} props={childProps} />
    <AppliedRoute path="/contact" component={AsyncContact} props={childProps} />
    <AppliedRoute path="/404" component={AsyncPageNotFound} props={childProps} />

    <AppliedRoute exact path="/blog" component={AsyncBlog} props={childProps} />
    <AppliedRoute path="/blog/page/:page" component={AsyncBlog} props={childProps} />
    <AppliedRoute path="/blog/tag/:tag/page/:page" component={AsyncBlogTag} props={childProps} />
    <AppliedRoute path="/blog/tag/:tag" component={AsyncBlogTag} props={childProps} />
    <AppliedRoute path="/blog/:category/page/:page" component={AsyncBlogCategory} props={childProps} />
    <AppliedRoute path="/blog/:category/:post" component={AsyncBlogPost} props={childProps} />
    <AppliedRoute path="/blog/:category" component={AsyncBlogCategory} props={childProps} />

    <AppliedRoute path="/admin/blog/:category/:post" component={AsyncBlogAdmin} props={childProps} />
    <AppliedRoute path="/admin/blog" component={AsyncBlogAdmin} props={childProps} />
    <AppliedRoute path="/admin/user/:userid" component={AsyncUserAdmin} props={childProps} />
    <AppliedRoute path="/admin/user" component={AsyncUserAdmin} props={childProps} />

    <AppliedRoute exact path="/podcast" component={AsyncPodcast} props={childProps} />
    <AppliedRoute path="/podcast/page/:page" component={AsyncPodcast} props={childProps} />

    <AppliedRoute path="/admin/podcast/:category/:post" component={AsyncPodcastAdmin} props={childProps} />
    <AppliedRoute path="/admin/podcast" component={AsyncPodcastAdmin} props={childProps} />

    <AppliedRoute path="/music" component={AsyncMusic} props={childProps} />
    <AppliedRoute path="/live" component={AsyncLive} props={childProps} />

    <AppliedRoute path="/profile" component={AsyncProfileMe} props={childProps} />
    <AppliedRoute path="/:userid" component={AsyncProfileView} props={childProps} />

  </Switch>;